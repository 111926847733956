<template>
  <h1>Forgoot 2 fac auth</h1>
  <default-input v-model="formdata.email" v-bind="form.email.attrs" :classes="form.email.classes"></default-input>
</template>

<script>
import defaultInput from "@/components/form-inputs/DefaultInput.vue";

export default {
  components: {
    defaultInput,
  },
  data() {
    return {
      formdata: {
        email: "",
      },
      form: {
        email: {
          attrs: {
            id: "email",
            type: "email",
            name: "email",
            label: "email",
            placeholder: "email@email.dk",
          },
          classes: {
            label: [],
            input: [],
          },
        },
      },
    };
  },
};
</script>
